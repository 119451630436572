@keyframes ARST {
    0% {
        transform: rotate(0);
    }    
    100% {
        transform: rotate(360deg);
    }
}
    
$num-letters: 13;
$angle-span: 360deg;
$angle-offset: 0deg;
$angle-per-char: $angle-span / $num-letters;

@for $i from 1 through $num-letters {
    .header-char#{$i} {
        transform : rotate(-$angle-offset + $angle-per-char * $i);
    }
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    color: var(--pink);
    @include pad();
    background-color: var(--green);
    height: 100px;
    width: 100%;
    position: fixed;
    top:0px;
    max-width: 395px;
    z-index: 100;
    .logo {
        transition: .1s cubic-bezier(.66,.14,.5,.9);
    }
    .logo-loading {
        position: relative;
        top: 20px;
        transform: scale(.7);
        a {
            position: relative;
            border-radius: 50%;
            transform: rotate(0);
            height: 100px;
            display: block;
            width: 100px;    
            animation: ARST 2s infinite linear;  
            span {
                height: 120px;
                position: absolute;
                width: 20px;
                left: 40px;
                top: -10px;
            }
        }
    }
}

.logo {
    font-weight: 900;
    font-size: 28px;
}