footer {
    @include pad();
    background-color: var(--green);
    color: var(--pink);
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 60px;
    h2:not(.logo) {
        font-size: 20px;
    }
}