.home {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.card {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 9px;
    background: var(--purple);
    color: var(--pink);
    gap: 10px;
    transition: all .3s;
    &-icons {
        display: flex;
        justify-content: space-between;
    }
    &-selectors {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    header {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
    }
    #map {
        display: none;
        background-color: var(--pink);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        transition: all .3s;
    }
    #map.opened {
        display:block;
        height: 138px;
    }
    &-content {
        position: relative;
        min-height: 36px;
        #searchBox {
            position: absolute;
            height: 36px;
            padding-right: 36px;
        }
        #localisation-button {
            position: absolute;
            right: 0;
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            cursor: pointer;
        }
    }
}

@keyframes size {
    from {
        order: 1;
    }
    to {
        // transform: scale(1.1);
        // opacity: 1;
        // width: 100%;
        order: 5;
        // filter:blur(5px);
    }
}

.wheel {
    transition: opacity .3s;
}

.result {
    // display: none;
    transition: opacity .3s;
    opacity: 0;
    position: relative;
    padding-top: 220px;
    flex-direction: column;
    gap:20px;
    p {
        color: var(--purple);
        font-size: 14px;
    }
    #restaurant-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
    }
    #map {
        height: 220px;
        width: 100%;
    }
    &-header {
            width: calc(100% + 40px);
            top: 0;
            margin-left: -20px;
            position: absolute;
            margin-top: -20px;
            height: 220px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            position: lative;
            overflow: hidden;
        .call-purple {
            padding: 20px;
            text-align: center;
            z-index: 10;
            font-size: 28px;
            color: var(--white);
            font-weight: 900;
            -webkit-text-stroke: 1px var(--purple);
            text-stroke: 1px var(--purple);
            text-shadow: 2px 2px 0 var(--purple);
        }
    }
}

@keyframes confettiRain {
    0% {
      opacity: 1;
      margin-top: -100vh;
      margin-left: -200px;
    } 
    
    100% {
      opacity: 1;
      margin-top: 100vh;
      margin-left: 200px;
    }
  }
  
  .confetti {
    opacity: 0;
    position: absolute;
    width: 1rem;
    height: 1.5rem;
    transition: 500ms ease;
    animation: confettiRain 7s infinite;
  }
  
  #confetti-wrapper {
     overflow: hidden !important;
     position: absolute;
     width: 100%;
     height: 220px;
     top: 0;
    //  backdrop-filter: blur(10px);
  }

.wheel-loader {
    display: grid;
    flex-direction: column;
    gap: 5px;
    margin-top: 40px;
    > .res {
        opacity: 1;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--purple);
        color: var(--pink);
        border-radius: 5px;
        filter: blur(5px);
        margin: auto;
        animation: size .5s ease-in-out infinite forwards;
        transition: all .25s;
        &:nth-child(1), &:nth-child(5) {
            transform: scale(.8);
            opacity: .2;
        }
        &:nth-child(2), &:nth-child(4) {
            transform: scale(.9);
            opacity: .6;
        }
    }
}

@for $i from 1 to 6 {
    .wheel-loader .res:nth-child(#{$i}) { 
        animation-delay: -$i * .1s; 
    }
  }