.button {
    background: white;
    min-height: 36px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    transition: all .3s;
    &:hover {
        opacity: .8;
    }
    &-text {
        padding: 10px;
        font-size: 14px;
        font-weight: normal;
        color: var(--purple);
    }
    &-cta {
        background-color: var(--purple);
        color: var(--white);
        font-size: 20px;
        font-weight: 900;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &-icon {
        min-width: 36px;
        justify-content: center;
        font-size: 20px;
    }
    &-click {
        opacity: .3;
        &:hover {
            opacity: 7;
        }
    }
}
.clicked {
    opacity: 1;
}