:root {
    --pink : #F6E8EA;
    --green : #00AA6C;
    --purple : #6F58C9;
    --white : #FFFFFF;
    --black : #1E1E1E;
}

* {
    box-sizing: border-box;
}

html, body {
    @include res();
    width: 100%;
    height: 100%;
    font-family: "Millimetre";
    font-weight: normal;
    background-color: var(--black);
}

button {
	background: transparent;
	border: none;
	font-family: inherit;
	@include res();
}

img {
    max-width: 100%;
}

input {
	@include res();
	width: 100%;
	min-height: 36px;
	border-radius: 5px;
	padding: 10px;
	box-sizing: border-box;
	font-size: 14px;
	border: none;
	color: var(--purple);
	outline: none;
    &::placeholder {
        opacity: 1;
    }
}

.separator {
    height: 1px;
    background-color: var(--pink);
}

#root {
    max-width: 395px;
    margin: auto;
    background-color: var(--pink);
}

p, h1, h2, h3, a {
    @include res();
}

a {
    text-decoration: none;
    color: inherit;
}

main {
    @include pad();
    padding-top: 120px;
}

.call-purple {
    font-size: 20px;
    color: var(--purple);
    font-weight: 800;
}