@font-face {
    font-family: "Millimetre";
    src:    url("../../webfonts/Regular/Millimetre-Regular_web.eot");
    src:    url("../../webfonts/Regular/Millimetre-Regular_web.otf") format("otf"), 
            url("../../webfonts/Regular/Millimetre-Regular_web.ttf") format("ttf"),
            url("../../webfonts/Regular/Millimetre-Regular_web.woff") format("woff"),
            url("../../webfonts/Regular/Millimetre-Regular_web.woff2") format("woff2");
    font-weight: normal;
}
@font-face {
    font-family: "Millimetre";
    src:    url("../../webfonts/Light/Millimetre-Light_web.eot");
    src:    url("../../webfonts/Light/Millimetre-Light_web.otf") format("otf"), 
            url("../../webfonts/Light/Millimetre-Light_web.ttf") format("ttf"),
            url("../../webfonts/Light/Millimetre-Light_web.woff") format("woff"),
            url("../../webfonts/Light/Millimetre-Light_web.woff2") format("woff2");
    font-weight: lighter;
}
@font-face {
    font-family: "Millimetre";
    src:    url("../../webfonts/Bold/Millimetre-Bold_web.eot");
    src:    url("../../webfonts/Bold/Millimetre-Bold_web.otf") format("otf"), 
            url("../../webfonts/Bold/Millimetre-Bold_web.ttf") format("ttf"),
            url("../../webfonts/Bold/Millimetre-Bold_web.woff") format("woff"),
            url("../../webfonts/Bold/Millimetre-Bold_web.woff2") format("woff2");
    font-weight: 800;
}
@font-face {
    font-family: "Millimetre";
    src:    url("../../webfonts/Extrablack/Millimetre-Extrablack_web.eot");
    src:    url("../../webfonts/Extrablack/Millimetre-Extrablack_web.otf") format("otf"), 
            url("../../webfonts/Extrablack/Millimetre-Extrablack_web.ttf") format("ttf"),
            url("../../webfonts/Extrablack/Millimetre-Extrablack_web.woff") format("woff"),
            url("../../webfonts/Extrablack/Millimetre-Extrablack_web.woff2") format("woff2");
    font-weight: 900;
}